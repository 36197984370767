.app__contact {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__contact-cards {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 4rem 2rem 2rem;

  .app__contact-card {
    min-width: 290px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;


    margin: 1rem 0;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: #fef4f5;
    transition: all 0.3s ease-in-out;

    img {
      width: 40px;
      height: 40px;
      margin: 0 0.7rem;
    }

    p {
      font-weight: 600;
    }

    a {
      text-decoration: none;
      font-weight: 500;
    }

    &:hover {
      box-shadow: 0 0 25px #fef4f5;
    }

    @media screen and (max-width: 450px) {
      min-width: 0px;
      width: 100%;

      img {
        margin: 0 0.5rem;
        width: 35px;
        height: 35px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.app__contact-card {
  background-color: #f2f7fb !important;
}

.app__contact-form {
  width: 60%;
  flex-direction: column;
  margin: 1rem 2rem;

  div {
    width: 100%;
    margin: 0.75rem 0;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--primary-color);

    transition: all 0.3s ease-in-out;

    input,
    textarea {
      width: 100%;
      padding: 0.95rem;
      border: none;
      border-radius: 7px;
      background-color: var(--primary-color);

      font-family: var(--font-base);
      color: var(--secondary-color) !important;
      outline: none;
    }

    textarea {
      height: 170px;
    }

    &:hover {
      box-shadow: 0 0 25px var(--primary-color);
    }
  }

  // button {
  //   padding: 1rem 2rem;
  //   border-radius: 10px;
  //   border: none;
  //   background-color: var(--secondary-color);

  //   font-weight: 500;
  //   color: var(--white-color);
  //   outline: none;
  //   margin-top: 2rem;
  //   font-family: var(--font-base);
  //   cursor: pointer;
  // }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
  }
}
