$gcolor: #17141d; // global color
$color_: #a1a1af; // text
$border: #28242f;
$blocks: #201c29;
$shadow: #070509;

@mixin font-size($sizeValue: 1.6) {
  font-size: $sizeValue + rem;
}

@import url("https://fonts.googleapis.com/css?family=Lato");


.tags a {
  text-decoration: none;
  white-space: nowrap;
  @include font-size(1);
  color: $color_;

  &.tag {
    display: block;
    padding: 0.3em 0.85em;
    margin: 2px;
    color: black;
    background-color: #EDF2F8;
    transition: transform 0.2s;
    border-radius: 8px;
    transition-timing-function: cubic-bezier(0.45, -0.85, 0.55, -0.45);

    &:hover {
      transform: scale(1.2);
      background: linear-gradient(to right, #ff8a00, #da1b60);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

  .tags {
    margin-top: 12px;
    display: flex;
    align-items: center;
flex-wrap: wrap;}

    .tags span {
      padding: 1em;
      text-transform: uppercase;
      transform: rotate(180deg);
      writing-mode: vertical-rl;
      border-left: 3px solid $border;
      background: linear-gradient(to right, #ff8a00, #da1b60);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    
  


  