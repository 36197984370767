.app__about {
  flex: 1;
  flex-direction: column;
  width: 100%;
}

.app__about-context {
  width: 100%;
  // height: fit-content;
  flex: 1;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2rem;
  padding: 1rem;

  @media screen and (max-width: 1000px) {
    width: 80%;
    flex-direction: column;
  }
}

.app__about-img {
  // padding: 0 0 1.2rem 0;
  div {
    // align-items: flex-start;
    background: linear-gradient(rgba($color: #6b7688, $alpha: 0.35), #fff);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: relative;
    // border: 1px solid black;
    img {
      position: absolute;
      bottom: 1.2rem;
      max-width: 100%;
      max-height: 100%;
      box-sizing: border-box;
      object-fit: cover;
      transform: scale(1.2);
    }
  }

  @media screen and (max-width: 768px) {
    width: 80%;
  }

  @media screen and (max-width: 300px) {
    div {
      width: 150px;
      height: 150px;
    }
  }
}

.app__about-data {
  width: 100%;
  margin: 2rem;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;

  h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  p {
    text-align: justify;
    font-size: 1rem;
    font-family: var(--font-base);
    span {
      font-weight: 800;
      color: var(--secondary-color);
    }
  }

  @media screen and (max-width: 1000px) {
    div:nth-child(3) {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.app__profiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1rem;
}

.app__profile-item {
  width: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;

  img {
    width: 100%;
    height: 170px;
    object-fit: cover;
    border-radius: 15px;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}
