

.drop {
    position: relative;
    width: 325px;
    height: 300px;
    box-shadow:
        inset 20px 20px 20px rgba(0, 0, 0, 0.05),
        25px 35px 20px rgba(0, 0, 0, 0.05),
        25px 30px 30px rgba(0, 0, 0, 0.05),
        inset -20px -20px 25px rgba(255, 255, 255, 0.9);
    transition: 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.drop:nth-child(1) {
    border-radius: 50%;
    cursor: pointer;
}

// .drop:hover {
//     border-radius: 40%;
// }

// .drop::before {
//     content: '';
//     position: absolute;
//     top: 50px;
//     left: 85px;
//     width: 35px;
//     height: 35px;
//     background: #FFF;
//     border-radius: 50%;
//     opacity: 0.9;
// }

// .drop::after {
//     content: '';
//     position: absolute;
//     top: 90px;
//     left: 110px;
//     width: 15px;
//     height: 15px;
//     background: #FFF;
//     border-radius: 50%;
//     opacity: 0.9;
// }

.content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 40px;
    gap: 15px;
}

.content h2 {
    position: relative;
    width: 80px;
    height: 80px;
    color: var(--clr);
    background: #EFF0F4;
    box-shadow: inset 2px 5px 10px rgba(0, 0, 0, 0.1),
        inset -2px -5px -10px rgba(255, 255, 255, 1),
        15px 15px 10px rgba(0, 0, 0, 0.05),
        15px 10px 15px rgba(0, 0, 0, 0.025);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
}

.content a {
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: var(--secondary-color);
    color: white;
    font-weight: 800;
    cursor: pointer;
    margin: 0.5rem;
    transition: all 0.3s ease;
}

.content img{
    width: 200px;
    height: 100px;
}

.content a:hover {
    opacity: 1;
}

// .content a::before {
//     content: '';
//     position: absolute;
//     top: 8px;
//     left: 50%;
//     transform: translateX(-50%);
//     width: 65%;
//     height: 5px;
//     border-radius: 5px;
//     background: rgba(255, 255, 255, 0.5);
// }

.content p {
    color: rgb(83, 76, 76);
}

@media (max-width: 600px) {
    .drop {
        width: 280px;
        height: 280px;
    }
}